import React, { FC } from 'react';
import classNames from 'classnames';
import usePagination from 'utils/hooks/usePagination';
import { PaginationProps } from './models';

import './Pagination.scss';
import Button from '../Button';

const Pagination: FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className = '',
  previousText = 'Previous',
  nextText = 'Next',
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  }) as any[];

  if (currentPage === 0 || paginationRange?.length < 2 || !paginationRange) {
    return null;
  }

  const onNext = () => onPageChange(currentPage + 1);

  const onPrevious = () => onPageChange(currentPage - 1);

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className={classNames('pagination-container', className)}>
      <li>
        <Button
          onClick={onPrevious}
          className={classNames('pagination-container__item', {
            'pagination-container__item--disabled': currentPage === 1,
          })}
          ariaLabel={previousText}
        >
          {previousText}
        </Button>
      </li>
      {paginationRange.map((pageNumber, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <Button
            className={classNames('pagination-container__item', {
              'pagination-container__item--selected': pageNumber === currentPage,
              'pagination-container__item--disabled pagination-container__item--ellipsis':
                typeof pageNumber === 'string',
            })}
            onClick={typeof pageNumber !== 'string' ? () => onPageChange(pageNumber) : undefined}
            ariaLabel={pageNumber}
          >
            {pageNumber}
          </Button>
        </li>
      ))}
      <li>
        <Button
          className={classNames('pagination-container__item', {
            'pagination-container__item--disabled': currentPage === lastPage,
          })}
          onClick={onNext}
          ariaLabel={nextText}
        >
          {nextText}
        </Button>
      </li>
    </ul>
  );
};

export default Pagination;

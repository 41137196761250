import { useMemo } from 'react';
import { UsePaginationHook } from './types';

const range = (start, end) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  ellipsis = '...',
}: UsePaginationHook) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // it's 5 because we have standard pages like firstPage, lastPage, 2x ellipsis, currentPage
    const totalPaginationPositions = siblingCount + 5;

    if (totalPaginationPositions >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
    const shouldShowLeftEllipsis = leftSiblingIndex > 2;
    const shouldShowRightEllipsis = rightSiblingIndex < totalPageCount - 2;
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;
    const sideItemsCount = 3 + 2 * siblingCount;

    if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const leftRange = range(1, sideItemsCount);

      return [...leftRange, ellipsis, totalPageCount];
    }

    if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) {
      const rightRange = range(totalPageCount - sideItemsCount + 1, totalPageCount);

      return [firstPageIndex, ellipsis, ...rightRange];
    }

    if (shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);

      return [firstPageIndex, ellipsis, ...middleRange, ellipsis, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);
};

export default usePagination;

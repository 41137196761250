const sortVideoItems = (
  umbracoVideosNodes: VideoPageTypes.VideoPageProps[],
  relatedVideosOrder?: string
): VideoPageTypes.VideoPageProps[] => {
  if (!relatedVideosOrder) {
    return umbracoVideosNodes;
  }

  const relatedVideosOrderArray = relatedVideosOrder?.split(',');

  if (!relatedVideosOrderArray?.length) {
    return umbracoVideosNodes;
  }

  const umbracoVideosNodesAssignedSorting: VideoPageTypes.VideoPageProps[] = [];

  relatedVideosOrderArray.forEach((videoId) => {
    const foundItem = umbracoVideosNodes.find((item) => {
      return item.id === Number(videoId);
    });
    if (foundItem) {
      umbracoVideosNodesAssignedSorting.push(foundItem);
    }
  });

  const umbracoVideosNodesNotAssignedSorting: VideoPageTypes.VideoPageProps[] = umbracoVideosNodes?.filter(
    (item) => !relatedVideosOrderArray.includes(String(item.id))
  );

  const umbracoVideosNodesSorted = [
    ...umbracoVideosNodesAssignedSorting,
    ...umbracoVideosNodesNotAssignedSorting,
  ];

  return umbracoVideosNodesSorted;
};

export default {
  sortVideoItems,
};

import React, { FC, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Modal } from '@phx-husky/modal';

import Button from 'common/Button';
import Carousel from 'common/Carousel';

import VideoListItem from './VideoListItem';
import VideoWithThumbnail from '../VideoNaturalBanner/VideoWithThumbnail/VideoWithThumbnail';
import Pagination from '../../common/Pagination';
import ScreenRecognitionContext from '../../utils/context/screenRecognitionContext';
import { VideoListProps } from './models';
import helpers from './helpers';

import './VideoList.scss';
import './MainVideoListOverride.scss';

const VideoList: FC<VideoListProps> = ({
  videoListSettings: { numberOfVideos, sectionTitle, playVideoText, topText },
  umbracoVideosNodes,
  relatedVideosOrder,
  withCarousel = false,
  carouselSettings,
  modalClassNameModifier,
}) => {
  const { isMobile } = useContext(ScreenRecognitionContext);
  const pageSize = isMobile ? 6 : numberOfVideos || 3;
  const [modalContent, setModalContent] = useState({
    title: '',
    description: '',
    videoImageAlt: '',
    videoCoverImage: '',
    youtubeVideo: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [isVisibleVideo, setIsVisibleVideo] = useState(false);
  const close = () => setShowModal(false);
  const [currentPage, setCurrentPage] = useState(1);

  const videoList = useMemo(() => {
    const umbracoVideosNodesSorted: VideoPageTypes.VideoPageProps[] = helpers.sortVideoItems(
      umbracoVideosNodes,
      relatedVideosOrder
    );
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    return umbracoVideosNodesSorted?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, relatedVideosOrder]);

  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const mobileCarouselSettings = {
    ...carouselSettings,
    slidesToShow: 1,
  };

  const videoListItems = () =>
    videoList?.map(({ title, description, videoImageAlt, videoCoverImage, youtubeVideo, id }) => (
      <VideoListItem
        key={title}
        {...{
          title,
          description,
          videoImageAlt,
          videoCoverImage,
          youtubeVideo,
          playVideoText,
          handleModalOpen,
          id,
        }}
      />
    ));

  return (
    <section className="video-list">
      {isMobile ? (
        <>
          {sectionTitle ? <h2 className="video-list__title">{sectionTitle}</h2> : null}
          {topText ? <h2 className="video-list__top-text">{topText}</h2> : null}
        </>
      ) : (
        <>
          {topText ? <h2 className="video-list__top-text">{topText}</h2> : null}
          <h2 className="video-list__title">{sectionTitle}</h2>
        </>
      )}
      <div className="video-list__row">
        {withCarousel && videoListItems() ? (
          <Carousel settings={isMobile ? mobileCarouselSettings : carouselSettings}>
            {videoListItems()}
          </Carousel>
        ) : (
          videoListItems()
        )}
      </div>
      <Modal isShowing={showModal} close={close}>
        <div
          className={classNames('modal__content', {
            [`modal__content--${modalClassNameModifier}`]: modalClassNameModifier,
          })}
        >
          <Button
            className="modal__content--button-close"
            onClick={close}
            ariaLabel="close button"
          />
          <VideoWithThumbnail
            {...{
              className: 'card__top--video-overlay',
              image: modalContent.videoCoverImage,
              setIsVisibleVideo,
              isVisibleVideo,
              imageAsBackgroundAlt: modalContent.videoImageAlt,
              bannerVariant: 'video',
              src: modalContent.youtubeVideo,
              title: modalContent.title,
              autoplay: true,
              isPlayButton: false,
            }}
          />
          <div className="video-list__card--title">
            <h2>{modalContent.title}</h2>
          </div>
          <div className="video-list__card--description">
            <p>{modalContent.description}</p>
          </div>
        </div>
      </Modal>
      <Pagination
        currentPage={currentPage}
        totalCount={umbracoVideosNodes?.length}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </section>
  );
};

export default VideoList;
